<script>
import AppButton from "@/components/atoms/AppButton.vue";

export default {
  name: "AppDialog",
  components: { AppButton },
  data() {
    return {};
  },
  props: {
    dialog: Boolean,
    title: String,
    text: String,
    buttonCancel: String,
    buttonConfirm: String,
  },
  methods: {
    clickConfirm() {
      this.$emit("clickConfirm");
    },
    clickCancel() {
      this.$emit("clickCancel");
    },
  },
  computed: {
    localValue: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("change", { name: this.name, value: val });
      },
    },
  },
};
</script>
<template>
  <v-dialog
    v-model="localValue"
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
  >
    <v-card>
      <span style="padding: 0; margin: 0">
        <v-toolbar class="dialog-title" color="white">{{ title }}</v-toolbar>
        <v-card-text class="dialog-text">
          {{ text }}
        </v-card-text>
      </span>
      <v-card-actions class="dialog-buttons">
        <app-button
          name="dialog-cancel"
          :text="buttonCancel"
          type="new-return"
          width="calc(50% - 8px)"
          @click="clickCancel"
        />
        <app-button
          name="dialog-confirm"
          :text="buttonConfirm"
          type="new-default"
          width="calc(50% - 8px)"
          @click="clickConfirm"
        />
        <!-- <v-btn text @click="clickCancel">{{ buttonCancel }}</v-btn>
        <v-btn text @click="clickConfirm">{{ buttonConfirm }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.v-dialog {
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25) !important;
  border-radius: 8px !important;

  .v-card {
    min-height: 288px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .dialog-buttons {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    justify-content: space-between;
  }

  .v-card.v-sheet {
    padding: 16px;
    border-radius: 20px !important;
  }

  .v-toolbar__content {
    padding: 0;
    height: auto !important;
  }

  .v-sheet.v-toolbar:not(.v-sheet--outlined) {
    border: none;
    box-shadow: none !important;
  }

  .dialog-title {
    height: auto !important;
    font-family: "Quicksand";
    padding: 16px;
    font-size: 22px;
    font-weight: 600;
    box-shadow: none !important;
    color: #323232;
  }

  .dialog-text {
    padding: 16px !important;
    font-size: 18px;
    font-weight: 400;

    color: rgba(50, 50, 50, 1);
    line-height: 26px;
  }
}
</style>
